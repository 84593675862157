import './Title.css'

function Title() {
  return (
    <div className='Title'>
      <h1>everydle</h1>
      <p>A definitive list of Wordle variants</p>
    </div>
  );
}

export default Title;
